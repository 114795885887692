<template>
<div>
    <v-card outlined flat tile dark class="light-blue darken-4">
        <v-card-text>
            <p class="display-1 ma-0">{{ number }}.</p>
            <h2 class="pa-0 ma-0 white--text font-weight-regular body-1" v-html="question?.texto ? question?.texto.replace(/\n/gi, '<br/>') : null"></h2>
        </v-card-text>
    </v-card>
    <v-card outlined flat tile>
        <v-card-text>
            <v-list-item-group v-model="answer">
                <template v-for="(alt, i) in question?.alternativas">
                    <v-list-item :key="i" :value="alt" color="blue">
                        <template v-slot:default="{ active }">
                            <v-list-item-action>
                                <v-icon color="blue" v-if="active">radio_button_checked</v-icon>
                                <v-icon v-else>radio_button_unchecked</v-icon>
                            </v-list-item-action>
                            <v-list-item-content :class="active ? 'font-weight-medium' : '' ">
                                {{ alt }}
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
            <v-expand-transition>
                <v-alert type="error" v-if="intentos > 0 && !correcto">
                    Respuesta incorrecta. No te preocupes, puedes intentar otra vez.
                </v-alert>
                <v-alert type="success" v-if="correcto">
                    ¡Respuesta correcta!.
                </v-alert>
            </v-expand-transition>
            <v-expand-transition>
                <v-card outlined flat v-if="(intentos >= 3 && !correcto) && !!question.ayuda">
                    <v-card-text>
                        <p class="text--primary">Una pequeña ayuda para responder la pregunta:</p>
                        <v-card outlined tile>
                            <v-card-text>
                                <cite class="subtitle-1">"{{ question.ayuda }}"</cite>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-expand-transition>
        </v-card-text>
    </v-card>
</div>
</template>

<script>

export default {
    props: ['value', 'question', 'number'],
    data: () => ({
        timer_success: null,
        correcto: false,
        intentos: 0,
        ayuda: null
    }),
    computed: {
        answer: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        reset() {
            this.answer = null;
            this.correcto = false;
            this.intentos = 0;
        },
        try_again() {
            this.intentos++;
            this.answer = null;
        },
        success() {
            console.log('Acierto, espera un poco');
            this.intentos = 0;
            this.correcto = true;
            this.timer_success = setInterval(this.kill_timer, 2000);
        },
        kill_timer() {
            console.log('killed');
            clearInterval(this.timer_success);
            this.timer_success = null;
            this.$emit('acierto');
        }
    }
}
</script>