<template>
    <v-fade-transition>
        <v-overlay v-model="visible" absolute opacity="1">
            <v-row class="pa-10 overflow-auto" style="max-height: 90vh;">
                <v-fade-transition>
                    <v-col cols="12">
                        <h1 class="py-4"><v-icon color="secondary" left>play_arrow</v-icon>{{ nombre }}</h1>
                        <h3>{{ descripcion || "Sin descripción" }}</h3>
                        <v-divider class="my-4" />
                        <span class="title-1">
                            {{ instrucciones }}
                            <br/>
                            <ul>
                                <li>
                                    Lea atentamente el material de apoyo antes de realizar la evaluación. Cuando considere que esta listo para responder presione en <span class="green--text font-weight-bold">"<v-icon color="green" left>done</v-icon>Comenzar evaluación".</span>
                                </li>
                                <li>
                                    Al comenzar la evaluación, no podrá retroceder a revisar el material de apoyo.
                                </li>
                                <li>
                                    Si la presentación no carga, o no se muestra en pantalla correctamente, presione el botón <b>"<v-icon left>refresh</v-icon>Recargar"</b>.
                                </li>
                            </ul>
                            <br />
                            Es altamente recomendado rendir esta evaluación en un computador o tableta, para minimizar problemas de visualización. <br/>
                            Una vez finalizada la evaluación, usted <span class="font-weight-bold red--text">no podrá rendirla nuevamente</span>.
                        </span>
                    </v-col>
                </v-fade-transition>
                <v-col cols="12" align="right">
                    <v-divider class="mb-4" />
                    <v-btn @click="emit_close" depressed text x-large outlined color="secondary" class="subtitle-2">
                        <v-icon left>play_arrow</v-icon>
                        Ir al material de apoyo
                    </v-btn>
                </v-col>
            </v-row>
        </v-overlay>
    </v-fade-transition>
</template>

<script>
export default {
    props: ['value'],
    methods: {
        emit_close() {
            this.visible = false;
            this.$emit("close");
        }
    },
    computed: {
        nombre() {
            if(!this.value) return "Sin título";
            return this.value.nombre;
        },
        descripcion() {
            if(!this.value) return "Sin descripción";
            return this.value.descripcion;
        },
        instrucciones() {
            if(!this.value) return this.default_instrucciones;
            if(!this.value.instruccion_material) return this.default_instrucciones;
            return this.value.instruccion_material;
        }
    },
    data: () => ({
        default_instrucciones: "Será necesario que lea el material de apoyo antes de comenzar con el cuestionario de esta evaluación.",
        visible: true
    })
}
</script>